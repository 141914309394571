body {
  font-family: "Rubik";
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.navbar-toggler {
  padding: 0 !important;
  border: 0 !important;
}

.navbar-collapse.show .navbar-nav {
  border-top: 2px solid black;
}

.nav-item {
  padding-left: 1em;
  font-size: 14px;
}
.nav-item.active a {
  font-weight: 600;
}
.nav-item a {
  text-decoration: none;
  position: relative;
  color: inherit;
  font-weight: 300;
}
.nav-item a:hover {
  color: black !important;
}
.nav-item:not(.active) a:not(.twitter-link)::after {
  position: absolute;
  content: "";
  height: 0.3em;
  top: 85%;
  background: currentColor;
  z-index: -1;
  left: 0;
  right: 0;
  transform: scaleX(0);
  transition: transform 0.5s cubic-bezier(0.95, 0.05, 0.795, 0.035);
}
.nav-item:not(.active) a:not(.twitter-link):hover::after {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform: scaleX(1);
}
.nav-item:not(.active) a.twitter-link:hover {
  color: #1FA1F1 !important;
}
.nav-item.last {
  border-right: 1px solid darkgray;
  padding-right: 1em;
}

.navbar-collapse.collapsing .nav-item.last,
.navbar-collapse.show .nav-item.last {
  border-right: 0;
}

.panel .panel-footer {
  transition: all 0.6s;
  transition-timing: ease-in-out;
  filter: grayscale(0.7);
  opacity: 0.5;
}
.panel .panel-footer h2 {
  text-align: center;
  font-weight: 600;
  font-size: 1.2em;
}
.panel img {
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}
.panel:hover img {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.panel:hover .panel-footer {
  filter: grayscale(0);
  opacity: 1;
}

.tooltipster-content {
  font-family: Rubik;
  font-size: 13px;
  padding: 1.5em !important;
}

a:hover h2 {
  text-decoration: none !important;
  color: black;
}

a:hover {
  text-decoration: none !important;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

#sending {
  display: none;
}

#sent {
  display: none;
  color: green;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
  background: #fbfbfd !important;
}

.modal-body {
  color: #666;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}


